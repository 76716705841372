<template>
	<div class="home">
        <v-row>
            <v-col lg="12" md="12" xs="12" cols="12">
                <v-tabs>
                    <v-tab v-on:click="onTabChange('alert')" v-if="showEditAlert == 1">ALERT</v-tab>
                    <v-tab v-on:click="onTabChange('permission')" v-if="showEditPermission == 1">PERMISSION</v-tab>
                </v-tabs>
            </v-col>
            <v-col lg="12" md="12" xs="12" cols="9">

                <v-row v-if="selectedConfig == 'alert' && showEditAlert == 1">
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <h3>Update Alert in Redis</h3>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea filled name="input-7-4" label="Fill in Alert Name (Seprate by Comma)"
                            v-model="alertName" rows="4">
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-btn v-on:click="writeAlertToRedis()" :loading="btnLoading" color="primary">WRITE TO REDIS</v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="selectedConfig == 'permission' && showEditPermission == 1">
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <h3>Update Sample Permission</h3>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-combobox  label="Filter Permission" dense v-model="selectedRole" :items="permissionList"
                            auto-select-first chips clearable deletable-chips small-chips></v-combobox>
                    </v-col>
                    <v-col cols="12">
                        <JsonEditor ref="jsonEditor" v-model="selectedPermission" v-if="permissionLoadJson" />
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="mt-5">
                        <v-btn v-on:click="samplePermissionHandler().update()" :loading="btnLoading" color="primary">
                            UPDATE PERMISSION
                        </v-btn>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
	</div>
</template>
<script>
import * as apiSettingsSamplePermission from "@components/api/pk/settings-sample-permission";
import * as apiRedisAlert from "@components/api/pk/redis-alert";
import JsonEditor from "@components/ComponentJsonEditor";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";

export default {
    components: { JsonEditor },
    props: {},
    mixins: [snackbar, permission],
    data () {
        return {
            showEditAlert: 0,
            showEditPermission: 0,
            permissionLoadJson: true,
            permissionData: [],
            permissionList: [],
            selectedPermission: [],
            selectedRole: "",
            permissionConfig: [],
            selectedConfig: "alert",
            alertName: "volume_alert, big_lot, mandate, profit_taker, locking_position, trade_on_credit, large_exposure, fast_trade, watch_list, tick_check, withdraw_deposit",
            btnLoading: false
        };
    },
    watch: {
        /**
         * select permission from arary of object according to the new filter
         * @param  {[type]} nv [description]
         * @param  {[type]} ov [description]
         * @return {[type]}    [description]
         */
        selectedRole (nv, ov) {
            this.samplePermissionHandler().load();
            const data = this.permissionData.filter(item => item.role == nv);

            if (data.length != 0) this.selectedPermission = data[0].permission;
        }
    },
    methods: {
        onTabChange (key) {
            this.selectedConfig = key;

            if (key == "permission") {
                this.samplePermissionHandler().load();
            }
        },
        samplePermissionHandler () {
            const self = this;
            return {
                load () {
                    apiSettingsSamplePermission.fetch().then(res => {
                        console.log(res);
                        self.permissionData = res.data;
                        self.permissionList = res.data.map(i => i.role);
                    });
                },
                update () {
                    self.btnLoading = true;
                    if (self.selectedRole == "" || self.selectedRole == null || self.selectedRole == undefined) {
                        self.snackBarDanger("Role cannot be empty");
                        return;
                    }
                    const params = { role: self.selectedRole, permission: self.selectedPermission };
                    apiSettingsSamplePermission.update(params).then(res => {
                        setTimeout(() => {
                            self.btnLoading = false;
                            self.snackBarSuccess("Update successfully");
                        }, 1500);
                    });
                }
            };
        },
        writeAlertToRedis () {
            const self = this;

            self.btnLoading = true;

            const params = { alert: self.alertName };

            apiRedisAlert.create(params).then(res => {
                setTimeout(() => {
                    self.btnLoading = false;
                    self.$store.state.snackbar = true;
                    self.$store.state.snackmsg = "Update successfully";
                    self.$store.state.snackcolor = "green";
                }, 1500);
            });
        }
    },
    mounted () {
        const userFunction = this.getFunction("account");

        if (userFunction.includes("editAlert")) {
            this.showEditAlert = 1;
        }
        if (userFunction.includes("editPermission")) {
            this.showEditPermission = 1;
        }

        this.samplePermissionHandler().load();
    }
};
</script>

<template>
	<div class="home">
		<v-card class="full-height-card">
			<v-container fluid>
				<v-row><v-col cols="12"></v-col></v-row>

			  	<v-row>
					<v-col cols="12" sm="12" md="12" lg="12" xl="12">
						<Config/>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>
<script>
import Breadcrumbs from "@components/Breadcrumbs";
import Security from "./components/ComponentSecurity";
import Config from "./components/ComponentConfig";
import { permission } from "@components/mixins/permission";
export default {
    components: { Breadcrumbs, Security, Config },
    mixins: [permission],
    data () {
        return {
            permission: []
        };
    },
    methods: {},
    mounted () {}
};
</script>

<template>
	<div class="home">

		<v-row>
			<v-col cols="12">
				<v-card outlined>
					<v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
						<div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
							SECURITY
						</div>
					</v-card-title>

					<v-row class="pa-4">
						<v-col cols="12" sm="12" md="12" lg="12" xl="12">
							<h3>Change Password</h3>
						</v-col>
						<v-col cols="12" sm="12" md="4" lg="3" xl="3">
							<v-text-field label="Password" type="password" v-model="password" hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="4" lg="3" xl="3">
							<v-text-field label="Retype Password" type="password" v-model="repassword" hide-details="auto"></v-text-field>
						</v-col>
					</v-row>
					<v-row class="pa-4">
						<v-col cols="12" sm="12" md="12" lg="12" xl="12">
							<v-btn v-on:click="updatePassword()" :loading="btnLoading" color="primary">UPDATE</v-btn>
						</v-col>
					</v-row>

				</v-card>
			</v-col>
		</v-row>

	</div>
</template>
<script>
import * as apiSecurity from "@components/api/pk/user-password";
export default {
    props: {
    },
    data () {
        return {
            password: "",
            repassword: "",
            btnLoading: false
        };
    },
    methods: {
        updatePassword () {
            const self = this;
            if (self.password == "" || self.repassword == "") {
                self.$store.state.snackbar = true;
                self.$store.state.snackcolor = "red";
                self.$store.state.snackmsg = "Password cannot be null or empty";
                return;
            }
            if (self.password != self.repassword) {
                self.$store.state.snackbar = true;
                self.$store.state.snackcolor = "red";
                self.$store.state.snackmsg = "Password does not match!";
                return;
            }
            self.btnLoading = true;
            const params = { password: self.password };
            apiSecurity.update(params).then(res => {
                setTimeout(() => {
                    self.btnLoading = false;
                    self.$store.state.snackbar = true;
                    self.$store.state.snackmsg = "Update successfully";
                    self.$store.state.snackcolor = "green";
                }, 1500);
            });
        }
    },
    mounted () {

    }
};
</script>
